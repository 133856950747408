// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import ignoreSentry from './ignoreSentry';

Sentry.init({
    dsn:
        process.env.NEXT_PUBLIC_SENTRY_DSN ||
        'https://0a92e652da048d5c94591b2bc401ea58@o4505040852418560.ingest.sentry.io/4506466175352832',
    tracesSampleRate: 1.0,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    ignoreErrors: [...ignoreSentry.ignoreErrors],
    denyUrls: ignoreSentry.denyUrls,
    hideSourceMaps: true,
});
